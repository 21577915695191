import React from "react";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          JY Brands
          <br />
          is Coming Soon
        </h1>

        <Timer />
        <p>Call or text (01)251-222-8522 for enquiries </p>

        <Preloader />
      </div>
    </div>
  );
}

export default App;
